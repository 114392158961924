<template>
  <!-- TOP NAVIGATION -->
  <div id="nav" v-show="$store.state.UI.visible">
    <router-link @click.native="disableActivesketch" v-for="(link, index) in $store.state.router.pages" :key="index" :to="{ name: link.name }" class="routerLink">
      {{ link.name }}
    </router-link>

    <!-- THEME SWITCHER-->
    <input type="checkbox" class="themeToggle" id="themeToggle" @click="toggleTheme">
    <label for="themeToggle" class="themeToggleComponent">
      <i class="fas fa-moon"><img :src="$store.getters.getPublicPath + 'UI/moon-solid.svg'" alt="Dark mode" /></i>
      <i class="fas fa-sun"><img :src="$store.getters.getPublicPath + 'UI/sun-solid.svg'" alt="Light mode" /></i>
      <span class="ball"></span>
    </label>
  </div>
  <div id="version" v-show="$store.state.UI.visible">Version {{ store.getters.appVersion }}</div>

  <!-- ROUTER VIEW-->
  <router-view />

  <!-- sketch SELECTOR -->
  <div id="sketchSelector" :class="$store.state.sketches.activesketch == null ? 'nosketchesActive' : ''">
    <!-- CREATE LINK FOR EACH sketch IN THE DATABASE -->
    <router-link :class="sketch.ID == $store.state.sketches.activesketch ? 'activesketch' : ''" :data-ID="sketch.ID" @click.native="switchsketch" v-for="(sketch, index) in $store.state.router.sketches" :key="index" :to="{ name: sketch.name }">
      <div class="sketchCoverArt">
        <img :src="`${publicPath}coverArt/` + sketch.ID + '.jpg'" :alt="sketch.name + ' cover art'">
      </div>
      <div class="sketchTitle">{{ sketch.name }}</div>

      <div class="playbackControls">&#9654;</div>
    </router-link>
  </div>

</template>

<script>
import { useStore } from "vuex";
import { consoleLog, consoleError, consoleSticky } from "@/utils/utils.js";
import { onMounted } from "vue";

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  setup() {
    const store = useStore();

    // Global variables and functions
    global.storeReference = store;
    global.log = consoleLog;
    global.error = consoleError;
    global.sticky = consoleSticky;

    // Set theme
    document.documentElement.className = store.state.UI.theme;

    onMounted(() => {
      // Check the URL for the /sketch/ directory and – if present – mark the sketch as active
      let url = window.location.href;
      if (url.indexOf("/sketch/") > -1) {
        let urlsplit = url.split("/sketch/");
        let activesketch = urlsplit[urlsplit.length - 1];
        store.dispatch("updateState", { parent: "sketches", key: "activesketch", value: activesketch });
      }

      // Update theme colors
      updateThemeColors();
    })

    // Toggle between light and dark themes
    function toggleTheme(e) {
      // Update theme value
      document.documentElement.className == "light" ? (document.documentElement.className = "dark") : (document.documentElement.className = "light");
      store.dispatch("updateState", { parent: "UI", key: "theme", value: document.documentElement.className });

      // Update color references from SCSS to the VueX state
      updateThemeColors();
    }

    // Update color references from SCSS to the VueX state
    function updateThemeColors() {
      let themeStyles = getComputedStyle(document.body)
      store.dispatch("updateState", { parent: "colors", key: "backgroundColor", value: themeStyles.getPropertyValue('--theme-' + store.state.UI.theme + '-backgroundColor') });
      store.dispatch("updateState", { parent: "colors", key: "textColor", value: themeStyles.getPropertyValue('--theme-' + store.state.UI.theme + '-textColor') });
      store.dispatch("updateState", { parent: "colors", key: "secondaryTextColor", value: themeStyles.getPropertyValue('--theme-' + store.state.UI.theme + '-secondaryTextColor') });
      store.dispatch("updateState", { parent: "colors", key: "linkColor", value: themeStyles.getPropertyValue('--theme-' + store.state.UI.theme + '-linkColor') });
    }

    // Set clicked element as active sketch in vueX state
    function switchsketch(e) {
      let clickedsketchID = e.currentTarget.getAttribute('data-ID');
      store.dispatch("updateState", { parent: "sketches", key: "activesketch", value: clickedsketchID });
    }

    // Set no sketch as active in vueX state
    function disableActivesketch() {
      store.dispatch("updateState", { parent: "sketches", key: "activesketch", value: null });
    }

    return { store, toggleTheme, switchsketch, disableActivesketch };
  },
};
</script>


<style lang="scss">
</style>
