import { markRaw } from 'vue';
import { createStore } from 'vuex'

export default createStore({
  state: {
    version: process.env.VUE_APP_VERSION || '0',
    publicPath: process.env.BASE_URL,
    enableStats: true,
    frameCount: 0,
    router: {
      pages: [],
      sketches: [],
    },
    colors: {
      backgroundColor: null,
      textColor: null,
      secondaryTextColor: null,
      linkColor: null
    },
    canvas: {
      canvas: null,
      ctx: null,
      width: 0,
      height: 0,
      retina: null,
      scale: 1,
      dpr: 1,
    },
    sketches: {
      activesketch: null,
    },

    helloWorld: {
      gpt_keyword: 'Sustainability',
      //gpt_prompt: 'Write a descriptive, quite literal prompt that a computer can understand on the topic of "sustainability" for an AI art generation software. The prompt should include interesting aspects, ideas or questions on the topic of sustainability.',
      // gpt_prompt: "Come up with a brand new description for a [painting, illustration, photograph] on the topic of sustainability, specifying the art style, the atmosphere, the framing of the subject, the lighting and the medium. Describe the subject. The prompt should include interesting aspects, ideas or questions on the topic of sustainability.",
      // gpt_prompt: "Come up with a brand new description for a photorealistic image with a positive tonality and warm colors on the topic of sustainability, specifying the framing of the subject and the lighting. Describe the subject. The prompt should include interesting aspects, ideas or questions on the topic of sustainability.",
      // gpt_prompt: "Come up with a brand new description for a photograph with a positive tonality and warm colors on the topic of sustainability, specifying the framing of the subject and the lighting, Sigma 85mm f/1.4. Describe the subject. The prompt should include interesting aspects, ideas or questions on the topic of sustainability.",
      gpt_prompt: "Come up with a brand new description for a photograph showing a snapshot of a technologically advanced, sustainable future 25 years from now with a positive tonality and warm colors on the topic of –––––––––, specifying the framing of the subject and the lighting, Sigma 85mm f/1.4. Describe the subject. The prompt should include interesting aspects, ideas or questions on the topic of ––––––––––",
      dalle_prompt: '',
      dalle_response: '',
      dalle_generatedImages: [],
      imagesToGenerate: 2,
      imageResolution: 256,
    },

    UI: {
      theme: "light",
      visible: true,
      windows: [],
      mountedWindows: [],
      windowZIndex: 1000,
      console: {
        content: "",
        sticky: "",
      },
      performance: {
        stats: {
          window: null,
        }
      },
    },
  },
  getters: {
    appVersion: (state) => {
      return state.version.replace(/["]+/g, '')
    },
    themeHasChanged: (state) => {
      return state.UI.theme;
    },
    getPublicPath: (state) => {
      return state.publicPath;
    }
  },
  mutations: {
    ANIMATION_INCREASE_FRAMECOUNT(state) {
      state.frameCount++;
    },
    CONSOLE_LOG(state, { log }) {
      state.UI.console.content += log + "<br/>";
    },
    CONSOLE_ERROR(state, { log }) {
      state.UI.console.content += '<span style="color: #ff0000 !important;">' + log + '</span>' + '<br/>';
    },
    CONSOLE_STICKY(state, { log }) {
      state.UI.console.sticky = log;
    },
    UPDATE_STATE(state, { parent, key, value }) {
      state[parent][key] = value;
    },
    UI_REGISTER_WINDOW(state, { window }) {
      state["UI"]["windows"].push(markRaw(window));
    },
    UI_MOUNT_WINDOW(state, { window }) {
      let newArray = state.UI.mountedWindows.concat([{ window: window, processed: false }]);
      state.UI.mountedWindows = newArray;
    },
    UI_TOGGLE_VISIBILITY(state) {
      state["UI"]["visible"] = !state["UI"]["visible"];
    },
    UPDATE_STATS(state, { stats }) {
      state.UI.performance.stats.geometries = stats.memory.geometries;
      state.UI.performance.stats.renderCalls = stats.render.calls;
      state.UI.performance.stats.frame = stats.render.frame;
      state.UI.performance.stats.triangles = stats.render.triangles;
    },
  },
  actions: {
    animation_increaseFrameCount({ commit }) {
      commit('ANIMATION_INCREASE_FRAMECOUNT');
    },
    consoleLog({ commit }, { log }) {
      commit('CONSOLE_LOG', { log });
    },
    consoleError({ commit }, { log }) {
      commit('CONSOLE_ERROR', { log });
    },
    consoleSticky({ commit }, { log }) {
      commit('CONSOLE_STICKY', { log });
    },
    updateState({ commit }, { parent, key, value }) {
      commit('UPDATE_STATE', { parent, key, value });
    },
    UI_registerWindow({ commit }, { window }) {
      commit('UI_REGISTER_WINDOW', { window });
    },
    UI_mountWindow({ commit }, { window }) {
      commit('UI_MOUNT_WINDOW', { window });
    },
    UI_toggleVisibility({ commit }) {
      commit('UI_TOGGLE_VISIBILITY');
    },
    updateStats({ commit }, { stats }) {
      commit('UPDATE_STATS', { stats });
    },
  },
  modules: {
  }
})
