// Vue router
import { createRouter, createWebHistory } from 'vue-router'

// Vue store
import store from '@/store/index.js';

// import all sketch-information from the JSON-encoded index-file
import sketches from '@/Sketches/index.json';

function initializeDynamicRouter() {
  let routes = {
    pages: [],
    sketches: [],
    combined: [],
  }


  // #1 Add static page routes
  routes.pages.push({ path: '/', name: 'Home', component: () => import('@/views/Home.vue') });
  routes.pages.push({ path: '/about', name: 'About', component: () => import('@/views/About.vue') });
  routes.combined.push(...routes.pages); // Merge with combined routes array
  store.dispatch("updateState", { parent: "router", key: "pages", value: routes.pages }); // Push routes array to vueX state



  // #2 Parse the sketch-information and build route objects based on that
  for (let index = 0; index < sketches.sketches.length; index++) {
    let sketch = sketches.sketches[index];
    let route = {
      ID: sketch.ID,
      path: '/sketch/' + sketch.link,
      name: sketch.title,
      component: () => import('../Sketches/' + sketch.ID + '/' + sketch.ID + '.vue') // Lazy load only when requested
    }
    routes.sketches.push(route);
  }
  routes.combined.push(...routes.sketches); // Merge with combined routes array
  store.dispatch("updateState", { parent: "router", key: "sketches", value: routes.sketches }); // Push routes array to vueX state



  // Return combined routes array
  return routes.combined;
}


function dynamicRouter() {
  return new Promise(resolve => {
    // Get routes
    let routes = initializeDynamicRouter();

    // Build router
    let router = createRouter({
      history: createWebHistory(process.env.BASE_URL),
      routes,
      scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
      }
    });

    // Resolve router to Vue instance
    resolve(router);
  });
}



export { dynamicRouter }
